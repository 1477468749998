
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDivider from "../components/atomic/section-divider"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "../resources/images/right-arrow.svg"
import Benefits from "../components/benefits"

import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function MobilneAplikacie() {

  const data = useStaticQuery(graphql`{
  webRef: file(relativePath: {eq: "gyg_show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef2: file(relativePath: {eq: "tjlforms-box.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  webRef3: file(relativePath: {eq: "doku-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef4: file(relativePath: {eq: "tasty-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  coop: file(relativePath: {eq: "coop.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Mobilné Aplikácie" />
      
     
      <div className="subhero">   
      <div className="hero">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="title"><h1>Robíme <span style={{color: "#F94166"}}>mobilné aplikácie</span> na mieru.</h1></div>
            <p>Vyvinieme pre Vás natívne mobilné aplikácie, ktoré sú rýchle, pekné, funkčné a bezpečné. Môžu byť samostatné, alebo pracovať s dátami, alebo inými systémami.</p>
        
          </div>
          </div>
        </div>
      </div> 
      </div>  
    

      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="Clear process" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black" style={{"paddingBottom": "40px"}}>
              <h2>Pozrite si ako postupujeme.</h2>
            </div>
            <div className="col-6 section-subtitle-black"><p>Máme jasný štandardizovaný proces, ktorý zabezpečí dodržanie termínov a prehľad.</p></div>
            
          </div>

          <div className="row mt40">
          
            <div className="col-6 process-list">
              <div className="number">1.</div>
              <h3>Architektúra systému a plán projektového manažmentu</h3>
              <div className="regular-text">Ak máme predstavu o riešení problému ďalšou úlohou nášho tímu je vytvoriť architektúru systému, ktorá obsahuje presné zadanie, ciele projektu a špecifikáciu, ktorej sa počas vývoja držíme. Súčasne vytvárame projektový plán a Gantt diagram jednotlivých pod-úloh.</div>
            </div>
            <div className="col-6 process-list">
              <div className="number">2.</div>
              <h3>UX/UI dizajn</h3>
              <div className="regular-text">Na základe požiadaviek klienta, architektúry systému, firemnej identity a našich skúseností vytvárame náhľady do aplikácie a jednoduchý klikateľný prototyp, pomocou ktorého je možné diskutovať o vzhľade a funkčnosti aplikácie.</div>
            </div>
          </div>
          <div className="row mt20">
            
            <div className="col-6 process-list">
              <div className="number">3.</div>
              <h3>Agilný vývoj </h3>
              <div className="regular-text">Počas programovania, ak nie je požadované inak, používame agilné metódy, scrum s dennými krátkymi stretnutiami a týždennými nasadeniami a testovaniami. Pomocou tejto metodológie vieme v akomkoľvek momente povedať či existuje nejaký sklz a aký veľký je a zároveň je celý proces ľahko sledovateľný aj pre klienta.</div>
            </div>
            <div className="col-6 process-list">
              <div className="number">4.</div>
              <h3>Nasadenie a ďalší vývoj</h3>
              <div className="regular-text">Nasadenie do živej prevádzky prebieha na základe plánu, ktorý si na začiatku zvolíme. Môžeme nasadzovať čiastočne pre málo používateľov za účelom získania spätnej väzby alebo naraz celú aplikáciu. Možností sú individuálne pre každý projekt. Po nasadení aplikáciu sledujeme výsledky, spätnú väzbu a analytické nástroje. Na základe toho ďalej navrhujeme zmeny a vylepšenia v aplikácii.</div>
            </div>
          </div>
        

      
        </div>
      </div>

      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="connection" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black" style={{"paddingBottom": "40px"}}>
              <h2>Vaše systémy prepojíme s existujúcimi alebo novými.</h2>
            </div>
            <div className="col-6 section-subtitle-black"><p>Nemusíte sa báť, Vaši zamestnanci nebudú musieť pracovať na jednotlivých úlohách v každom systéme zvlášť. Systémy môžeme prepojiť a procesy automatizovať.</p></div>
            
          </div>

          <div className="row">
            <div className="col-12">
            <p style={{"fontWeight":"600"}}>Spájali sme napríklad:</p>
            <GatsbyImage
              image={data.coop.childImageSharp.gatsbyImageData}
              style={{"height":"100%"}}
              objectFit="cover"
              objectPosition="50% 50%" />  
            </div>
            
          </div>

          
        

      
        </div>
      </div>

      <div className="section-pd section-pd black-text white-bg-cut">
      <div className="container">
        <SectionDivider name="references" white={false}/>
        <div className="row">
          <div className="col-4 section-heading">
            <h2>Pozrite sa na čom sme pracovali.</h2>
          </div>
          <div className="col-4 section-subtitle-black mt20">
            <p>Robili sme na zaujímavých projektoch, projekty, ktoré pomáhajú ľuďom. Veď presvedčte sa sami.</p>
          </div>
        </div>

        <div className="row mt40">

        <div className="col-6">
          <AniLink cover direction="left" to="/referencie/gyg" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Softvér na mieru" />  
              <div className="ref-button">
                Softvér na mieru
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tjl-app" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef2.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Webová aplikácia" />  
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
        <div className="row mt40">

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/doku" bg="#000" duration={1}><div className="ref-card">
              <GatsbyImage
                image={data.webRef3.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Web aplikácia" />  
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div></AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tasty" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef4.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Mobilná aplikácia" />  
              <div className="ref-button">
                Mobilná aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
          
           
      </div>
          
    </div>
    <Benefits />
    </Layout>
  );

}
